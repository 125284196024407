import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { orderReducer } from './reducers/order.reducers';
import { OrderEffects } from './effects/order.effects';
import { ORDER_FEATURE_KEY } from './keys';

@NgModule({
    imports: [StoreModule.forFeature(ORDER_FEATURE_KEY, orderReducer), EffectsModule.forFeature([OrderEffects])],
})
export class OrderStoreModule {}
