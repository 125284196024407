import { Injectable } from '@angular/core';
import { catchError, map, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '@app/shared/models/api-response.model';
import { CheckPrerequisitesDTO, OrderModel, OrderModelDto, OrderUpdateValue, OrderValue, PrerequisitiesValueDto } from '../models';
import { OrderValueDto } from '../models/order.value.dto';
import { OrderUpdateValueDto } from '../models/order.value.dto';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    constructor(private httpClient: HttpClient) {}

    getOrder(id: string): Observable<OrderModel> {
        return this.httpClient.get<ApiResponse<OrderModelDto>>(`order/${id}`).pipe(
            map((response) => new OrderModel(response.data)),
            catchError((error) => {
                throw error;
            }),
        );
    }

    updateOrder(id: string, value: OrderUpdateValue): Observable<OrderModel> {
        const params = new OrderUpdateValueDto(value);
        return this.httpClient
            .skipErrorHandler()
            .put<ApiResponse<OrderModelDto>>(`order/${id}`, params)
            .pipe(map((response) => new OrderModel(response.data)));
    }

    addOrder(value: OrderValue): Observable<OrderModel> {
        const params = new OrderValueDto(value);
        return this.httpClient.post<ApiResponse<OrderModelDto>>('order', params).pipe(map((response) => new OrderModel(response.data)));
    }

    checkPrerequisitiesUpdate(value: OrderUpdateValue, orderId: string) {
        const params = new PrerequisitiesValueDto(value, orderId);
        return this.httpClient.post<ApiResponse<CheckPrerequisitesDTO>>('order/check-prerequisites', params).pipe(map((resposne) => resposne.data));
    }

    checkPrerequisitiesCreate(value: OrderValue) {
        const params = new PrerequisitiesValueDto(value);
        return this.httpClient.post<ApiResponse<CheckPrerequisitesDTO>>('order/check-prerequisites', params).pipe(map((resposne) => resposne.data));
    }
}
