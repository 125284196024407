import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ORDER_FEATURE_KEY } from '../keys';
import { OrderState } from '../reducers/order.reducers';

export const selectState = createFeatureSelector<OrderState>(ORDER_FEATURE_KEY);
export const selectOrder = createSelector(selectState, (state) => state.order);
export const selectLoading = createSelector(selectState, (state) => state.loading);
export const selectLoaded = createSelector(selectState, (state) => state.loaded);
export const selectSaving = createSelector(selectState, (state) => state.saving);
export const selectSaved = createSelector(selectState, (state) => state.saved);
export const selectSavedId = createSelector(selectState, (state) => state.savedId);
export const selectPrerequisities = createSelector(selectState, (state) => state.prerequisities);
