import { Time } from '@app/shared/classes';
import { CURRENCY_TYPES, ITEM_STATUS, PAYMENT_STATUS, PAYMENT_TYPES, SERVICE_TYPES } from '../enums';
import { ItemDto, PaymentsOrderModelDto, TransactionDto } from './transaction.dto';

export class ItemOrderModel {
    id: string;
    bookingId: string;
    paymentId: string | null;
    transactionId: string;
    type: SERVICE_TYPES;
    resultStatus: ITEM_STATUS;
    price: number;
    name: string;
    notes: string | null;

    constructor(data: ItemDto) {
        this.id = data.id;
        this.bookingId = data.bookingId;
        this.paymentId = data.paymentId;
        this.transactionId = data.transactionId;
        this.type = data.type;
        this.resultStatus = data.resultStatus; // #FIXME
        this.price = data.price / 100;
        this.name = data.name;
        this.notes = data.notes;
    }
}

export class TransactionOrderModel {
    id: string;
    paymentId: string | null;
    currency: CURRENCY_TYPES | null;
    closed: boolean;
    items: ItemOrderModel[];
    constructor(data: TransactionDto) {
        this.id = data.id;
        this.paymentId = data.paymentId;
        this.currency = data.currency;
        this.closed = data.closed;
        this.items = data.items.map((item) => new ItemOrderModel(item));
    }
}

export class PaymentsOrderModel {
    id: string;
    amount: number;
    currency: string;
    method: PAYMENT_TYPES | null;
    status: PAYMENT_STATUS;
    redirectUrl: string | null;
    fiscalizedAt: string | null;

    constructor(data: PaymentsOrderModelDto) {
        this.id = data.id;
        this.amount = data.amount / 100;
        this.currency = data.currency;
        this.method = data.provider;
        this.status = data.status;
        this.redirectUrl = data.redirectUrl;
        this.fiscalizedAt = data.fiscalizedAt ? Time.dateToString(new Date(data.fiscalizedAt)) : null;
    }
}
