import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs';
import { OrderActions } from '../actions/order.actions';
import { CheckPrerequisitesModel, OrderModel, OrderUpdateValue, OrderValue } from '../models';
import { selectOrder, selectLoaded, selectLoading, selectSaved, selectSaving, selectSavedId, selectPrerequisities } from '../selectors/order.selectors';

@Injectable({
    providedIn: 'root',
})
export class OrderFacade {
    order$ = this.store.select(selectOrder).pipe(filter((order): order is OrderModel => order !== null));
    loaded$ = this.store.select(selectLoaded).pipe(filter((loaded) => loaded !== null));
    loading$ = this.store.select(selectLoading).pipe(filter((loading) => loading !== null));
    saved$ = this.store.select(selectSaved).pipe(filter((saved) => saved !== null));
    savedId$ = this.store.select(selectSavedId).pipe(filter((savedId) => savedId !== null));
    saving$ = this.store.select(selectSaving).pipe(filter((saving) => saving !== null));
    checkPrerequisities$ = this.store
        .select(selectPrerequisities)
        .pipe(filter((prerequisities): prerequisities is CheckPrerequisitesModel => prerequisities !== null));

    constructor(private store: Store) {}

    getOrder(id: string): void {
        this.store.dispatch(OrderActions.getOrder({ id }));
    }

    updateOrder(id: string, value: OrderUpdateValue) {
        this.store.dispatch(OrderActions.updateOrder({ id, value }));
    }

    addOrder(value: OrderValue) {
        this.store.dispatch(OrderActions.addOrder({ value }));
    }

    checkPrerequisitiesUpdate(value: OrderUpdateValue, orderId: string) {
        this.store.dispatch(OrderActions.checkOrderPrerequisitiesUpdate({ value, orderId }));
    }

    checkPrerequisitiesCreate(value: OrderValue) {
        this.store.dispatch(OrderActions.checkOrderPrerequisitiesCreate({ value }));
    }

    clear(): void {
        this.store.dispatch(OrderActions.clear());
    }
}
