import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, catchError, map, mergeMap, tap, finalize } from 'rxjs';
import { GuiFacade } from '@app/gui/store/facades/gui-facade';
import { OrderActions } from '../actions/order.actions';
import { HttpService } from '../services/http.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ResponseError } from '@app/shared/models/api-response.dtos';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class OrderEffects {
    getOrder$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(OrderActions.getOrder),
            mergeMap((action) => {
                this.guiFacade.showLoader('get-order');
                return this.httpService.getOrder(action.id).pipe(
                    map((response) => OrderActions.getOrderSuccess({ payload: response })),
                    catchError((error: HttpErrorResponse) => of(OrderActions.getOrderError({ error }))),
                );
            }),
        );
    });

    getOrderAlways$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(OrderActions.getOrderSuccess, OrderActions.getOrderError),
                tap(() => this.guiFacade.hideLoader('get-order')),
            );
        },
        {
            dispatch: false,
        },
    );

    updateOrder$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(OrderActions.updateOrder),
            mergeMap((action) => {
                this.guiFacade.showLoader('update-order');
                return this.httpService.updateOrder(action.id, action.value).pipe(
                    map((payload) => OrderActions.updateOrderSuccess({ payload })),
                    catchError((error: HttpErrorResponse) => of(OrderActions.updateOrderError({ error }))),
                );
            }),
        );
    });

    updateOrderSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(OrderActions.updateOrderSuccess),
                tap(() => {
                    this.guiFacade.hideLoader('update-order');
                    this.guiFacade.showSuccessMessage('GUI.TOAST.saved');
                }),
            );
        },
        { dispatch: false },
    );

    updateOrderError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(OrderActions.updateOrderError),
                tap((action) => {
                    this.guiFacade.hideLoader('update-order');
                    const apiError: ResponseError[] = (action.error?.error?.errors as ResponseError[]) ?? [];
                    if (apiError && apiError.length) {
                        apiError.forEach((er) => {
                            this.guiFacade.showErrorMessage(this.translateService.instant(er.title));
                        });
                    }
                }),
            );
        },
        { dispatch: false },
    );

    addOrder$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(OrderActions.addOrder),
            mergeMap((action) => {
                this.guiFacade.showLoader('add-order');
                return this.httpService.addOrder(action.value).pipe(
                    map((payload) => OrderActions.addOrderSuccess({ payload })),
                    catchError((error: HttpErrorResponse) => of(OrderActions.addOrderError({ error }))),
                );
            }),
        );
    });

    addOrderSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(OrderActions.addOrderSuccess),
                tap(() => {
                    this.guiFacade.hideLoader('add-order');
                    this.guiFacade.showSuccessMessage('GUI.TOAST.saved');
                }),
            );
        },
        { dispatch: false },
    );

    addOrderError$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(OrderActions.addOrderError),
                tap((action) => {
                    this.guiFacade.hideLoader('add-order');
                    const apiError: ResponseError[] = (action.error?.error?.errors as ResponseError[]) ?? [];
                    if (apiError && apiError.length) {
                        apiError.forEach((er) => {
                            this.guiFacade.showErrorMessage(this.translateService.instant(er.title));
                        });
                    }
                }),
            );
        },
        { dispatch: false },
    );

    checkPrerequisitiesCreate$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(OrderActions.checkOrderPrerequisitiesCreate),
            mergeMap((action) => {
                this.guiFacade.showLoader('check-prerequisities');
                return this.httpService.checkPrerequisitiesCreate(action.value).pipe(
                    map((payload) => OrderActions.checkOrderPrerequisitiesCreateSuccess({ prerequisities: payload })),
                    catchError((error: HttpErrorResponse) => of(OrderActions.checkOrderPrerequisitiesCreateError({ error }))),
                    finalize(() => this.guiFacade.hideLoader('check-prerequisities')),
                );
            }),
        );
    });

    checkPrerequisitiesUpdate$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(OrderActions.checkOrderPrerequisitiesUpdate),
            mergeMap((action) => {
                this.guiFacade.showLoader('check-prerequisities');
                return this.httpService.checkPrerequisitiesUpdate(action.value, action.orderId).pipe(
                    map((payload) => OrderActions.checkOrderPrerequisitiesUpdateSuccess({ prerequisities: payload })),
                    catchError((error: HttpErrorResponse) => of(OrderActions.checkOrderPrerequisitiesUpdateError({ error }))),
                    finalize(() => this.guiFacade.hideLoader('check-prerequisities')),
                );
            }),
        );
    });

    constructor(
        private actions$: Actions,
        private httpService: HttpService,
        private guiFacade: GuiFacade,
        private translateService: TranslateService,
    ) {}
}
