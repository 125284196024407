import { createReducer, on } from '@ngrx/store';
import { OrderActions } from '../actions/order.actions';
import { CheckPrerequisitesModel, OrderModel } from '../models';

export interface OrderState {
    order: OrderModel | null;
    loading: boolean | null;
    loaded: boolean | null;
    saving: boolean | null;
    saved: boolean | null;
    savedId: string | null;
    prerequisities: CheckPrerequisitesModel | null;
}

export const initialState: OrderState = {
    order: null,
    loading: null,
    loaded: null,
    saving: null,
    saved: null,
    savedId: null,
    prerequisities: null,
};

export const orderReducer = createReducer(
    initialState,
    on(
        OrderActions.getOrder,
        (state): OrderState => ({
            ...state,
            loading: true,
        }),
    ),
    on(
        OrderActions.getOrderSuccess,
        (state, action): OrderState => ({
            ...state,
            order: action.payload,
            loading: false,
            loaded: true,
        }),
    ),
    on(
        OrderActions.getOrderError,
        (state): OrderState => ({
            ...state,
            loading: false,
            loaded: false,
        }),
    ),

    on(
        OrderActions.updateOrder,
        (state): OrderState => ({
            ...state,
            saving: true,
        }),
    ),
    on(
        OrderActions.updateOrderSuccess,
        (state, action): OrderState => ({
            ...state,
            saving: false,
            saved: true,
            savedId: action.payload.id,
        }),
    ),
    on(
        OrderActions.updateOrderError,
        (state): OrderState => ({
            ...state,
            saving: false,
            saved: false,
            savedId: null,
        }),
    ),
    on(
        OrderActions.addOrder,
        (state): OrderState => ({
            ...state,
            saving: true,
        }),
    ),
    on(
        OrderActions.addOrderSuccess,
        (state, action): OrderState => ({
            ...state,
            saving: false,
            saved: true,
            savedId: action.payload.id,
        }),
    ),
    on(
        OrderActions.addOrderError,
        (state): OrderState => ({
            ...state,
            saving: false,
            saved: false,
            savedId: null,
        }),
    ),

    on(
        OrderActions.checkOrderPrerequisitiesCreate,
        (state): OrderState => ({
            ...state,
            prerequisities: null,
        }),
    ),
    on(
        OrderActions.checkOrderPrerequisitiesCreateSuccess,
        (state, payload): OrderState => ({
            ...state,
            prerequisities: payload.prerequisities,
        }),
    ),
    on(
        OrderActions.checkOrderPrerequisitiesCreateError,
        (state): OrderState => ({
            ...state,
            prerequisities: null,
        }),
    ),

    on(
        OrderActions.checkOrderPrerequisitiesUpdate,
        (state): OrderState => ({
            ...state,
            prerequisities: null,
        }),
    ),
    on(
        OrderActions.checkOrderPrerequisitiesUpdateSuccess,
        (state, payload): OrderState => ({
            ...state,
            prerequisities: payload.prerequisities,
        }),
    ),
    on(
        OrderActions.checkOrderPrerequisitiesUpdateError,
        (state): OrderState => ({
            ...state,
            prerequisities: null,
        }),
    ),
    on(
        OrderActions.clear,
        (state): OrderState => ({
            ...state,
            ...initialState,
        }),
    ),
);
