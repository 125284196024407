export enum PAYMENT_STATUS {
    PENDING = 'pending',
    COMPLETED = 'completed',
    CANCELLED = 'cancelled',
    PAID = 'paid',
    OK = 'ok',
    FAILED = 'failed',
    // RESIGNED = 'resigned',
    // UNKNOWN = 'unknown',
}

export enum ITEM_STATUS {
    PENDING = 'pending',
    COMPLETED = 'completed',
    RESIGNED = 'resigned',
    UNKNOWN = 'unknown',
    // RESIGNED = 'resigned',
    // UNKNOWN = 'unknown',
}
