import { CheckPrerequisitesDTO } from './has-duplicate.dto';
import {
    OrderDetailsValue,
    OrderParcelCreateValue,
    OrderParcelUpdateValue,
    OrderPassengerCreateValue,
    OrderPassengerUpdateValue,
    OrderUpdateValue,
    OrderValue,
} from './order.value';

export class PrerequisitiesValueDto {
    orderId?: string;
    customer: PrerequisitiesCustomerValueDto;
    passengers?: PrerequisitiesPassengersValueDto[];
    parcels?: PrerequisitiesParcelValueDto[];
    constructor(data: OrderValue | OrderUpdateValue, orderId?: string) {
        this.orderId = orderId;
        this.customer = new PrerequisitiesCustomerValueDto(data.details);
        this.passengers = data.passengers?.map((pass) => new PrerequisitiesPassengersValueDto(pass));
        this.parcels = data.parcels?.map((parcel) => new PrerequisitiesParcelValueDto(parcel));
    }
}

export class PrerequisitiesCustomerValueDto {
    firstName: string | null;
    lastName: string | null;
    constructor(data: OrderDetailsValue) {
        this.firstName = data.firstName;
        this.lastName = data.lastName;
    }
}
export class PrerequisitiesPassengersValueDto {
    firstName: string | null;
    lastName: string | null;
    route: { departureDate: string | null; planningType: number | null };
    constructor(data: OrderPassengerCreateValue | OrderPassengerUpdateValue) {
        this.route = { departureDate: null, planningType: null };
        this.firstName = data.personalDetails.firstname;
        this.lastName = data.personalDetails.surname;
        this.route.departureDate = data.departureDate
            ? `${data.departureDate.getFullYear()}-${(data.departureDate.getMonth() + 1).toString().padStart(2, '0')}-${data.departureDate.getDate().toString().padStart(2, '0')}`
            : null;
        this.route.planningType = data.planningType;
    }
}
export class PrerequisitiesParcelValueDto {
    route: { departureDate: string | null; planningType: number | null };
    constructor(data: OrderParcelCreateValue | OrderParcelUpdateValue) {
        this.route = { departureDate: null, planningType: null };
        this.route.departureDate = data.departureDate
            ? `${data.departureDate.getFullYear()}-${(data.departureDate.getMonth() + 1).toString().padStart(2, '0')}-${data.departureDate.getDate().toString().padStart(2, '0')}`
            : null;
        this.route.planningType = data.planningType;
    }
}

export class CheckPrerequisitesModel {
    hasDuplicate: boolean;
    isPast: boolean;
    constructor(data: CheckPrerequisitesDTO) {
        this.hasDuplicate = data.hasDuplicate;
        this.isPast = data.isPast;
    }
}
