export enum PAYMENT_TYPES {
    CASH = 'cash',
    TRANSFER = 'transfer',
    COMPANY_INVOICE = 'invoice',
    FREE_CHECKOUT = 'free_checkout',
    PAYU = 'payu',
    STRIPE = 'stripe',
    GROUP_SETTLEMENT = 'group_settlement',
}

export enum PAYMENT_PROVIDERS {
    PAYU = 'payu',
    STRIPE = 'stripe',
}
