export enum SERVICE_TYPES {
    RIDE = 1,
    LUGGAGE = 2,
    SUPPLEMENT = 4,
    DISCOUNT = 5,
    RETURN_ON_RESIGNATION = 6,
    GENERIC = 7,
}

export enum PAYMENT_PASSENGER_SERVICE_TYPES {
    RIDE = 1,
    LUGGAGE = 2,
    SUPPLEMENT = 4,
    DISCOUNT = 5,
    RETURN_ON_RESIGNATION = 6,
    GENERIC = 7,
}

export enum PAYMENT_PARCEL_SERVICE_TYPES {
    RIDE = 1,
    SUPPLEMENT = 4,
    DISCOUNT = 5,
    GENERIC = 7,
}
