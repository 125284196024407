import { OrderRouteAddressModel, OrderCustomerAddress } from './order.addresses.model';
import { BookingRouteDto, FilterDto, OrderBookingDto, OrderCustomerDto, OrderModelDto, OrderPassengerDto, OrderRouteDto } from './order.dto';
import { ItemOrderModel, PaymentsOrderModel, TransactionOrderModel } from '@app/store/order';
import { GeoAddressModel } from '@app/store/planning/models';
import { CUSTOMER_TYPE } from '../enums/customer-type.enum';
import { PartnerModel, Itineraries } from '../../models';
import { ParcelOrderDto, ParcelRecipientDTO } from '@app/store/parcels';

export class OrderRouteModel {
    id?: string | null;
    panelId: string | null;
    departureDate: Date;
    beforeNoon: boolean;
    to: GeoAddressModel;
    from: GeoAddressModel;

    constructor(data: OrderRouteDto) {
        this.id = data.id ?? null;
        this.panelId = data.panelId;
        this.departureDate = new Date(data.departureDate);
        this.beforeNoon = data.beforeNoon;
        this.from = new GeoAddressModel(data.from);
        this.to = new GeoAddressModel(data.to);
    }
}

export class OrderCustomerModel {
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    phone: string | null;
    companyName: string | null;
    nip: string | null;
    address: OrderCustomerAddress;
    type: CUSTOMER_TYPE;

    constructor(data: OrderCustomerDto) {
        this.firstName = data.firstName;
        this.lastName = data.lastName;
        this.email = data.email;
        this.phone = data.phone;
        this.companyName = data.companyName;
        this.nip = data.nip;
        this.address = new OrderCustomerAddress(data.address);
        this.type = data.type;
    }
}

export class OrderPassengerModel {
    id?: string;
    firstName: string | null;
    lastName: string | null;
    phone: string | null;
    email: string | null;

    constructor(data: OrderPassengerDto) {
        this.id = data.id;
        this.firstName = data.firstName;
        this.lastName = data.lastName;
        this.phone = data.phone;
        this.email = data.email;
    }
}

export class ParcelRecipientModel {
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    phone: string | null;
    companyName: string | null;
    nip: string | null;

    constructor(data: ParcelRecipientDTO) {
        this.firstName = data.firstName;
        this.lastName = data.lastName;
        this.email = data.email;
        this.phone = data.phone;
        this.companyName = data.companyName;
        this.nip = data.nip;
    }
}

export class OrderParcelModel {
    id?: string;
    recipient: ParcelRecipientModel | null;
    size: string;
    weight: number;
    value: number;
    contentDescription: string | null;
    internalNotes: string | null;
    driverNotes: string | null;
    deliveryDate: Date | null;
    deliveryNotes: string | null;
    pickupDate: Date | null;
    pickupNotes: string | null;
    deliveryCode: number | null;
    constructor(data: ParcelOrderDto) {
        this.id = data.id;
        this.recipient = new ParcelRecipientModel(data.recipient);
        this.size = data.size;
        this.weight = data.weight;
        this.value = data.value;
        this.contentDescription = data.contentDescription;
        this.driverNotes = data.driverNotes;
        this.internalNotes = data.internalNotes;
        this.deliveryDate = data.deliveryDate ? new Date(data.deliveryDate) : null;
        this.deliveryNotes = data.deliveryNotes;
        this.pickupDate = data.pickupDate ? new Date(data.pickupDate) : null;
        this.pickupNotes = data.pickupNotes;
        this.deliveryCode = data.deliveryCode;
    }
}

export class BookingRouteModel {
    departureDate: Date | null;
    beforeNoon?: boolean;
    from: OrderRouteAddressModel | null;
    to: OrderRouteAddressModel | null;

    constructor(data: BookingRouteDto) {
        this.departureDate = new Date(data.departureDate);
        this.beforeNoon = data.beforeNoon;
        this.from = data.from ? new OrderRouteAddressModel(data.from) : null;
        this.to = data.to ? new OrderRouteAddressModel(data.to) : null;
    }
}

export class FilterModel {
    id: string;
    name: string;
    color: string;
    date: Date;
    planningType: number;
    bookings: string[];
    default: boolean;
    createdAt: Date | null;

    constructor(data: FilterDto) {
        this.id = data.id;
        this.name = data.name;
        this.color = data.color;
        this.date = new Date(data.date);
        this.planningType = data.planningType;
        this.bookings = data.bookings;
        this.default = data.default;
        this.createdAt = data.createdAt ? new Date(data.createdAt) : null;
    }
}

export class OrderBookingPersonModel {
    id: string;
    fiscalizedAt: Date | null;
    planningType: number;
    dateHistory: string[];
    filter: FilterModel | null;
    computedItemRidePaymentStatus: string;
    itineraries: Itineraries;
    notes: string | null;
    driverNotes: string | null;
    internalNotes: string | null;
    route: BookingRouteModel;
    items: ItemOrderModel[];
    didntGo: boolean;
    externalId: number | null;
    uniqueNumber: number | null;
    type: number;
    passengerId: string;
    passenger: OrderPassengerModel;
    importDepartureAddress: string | null;
    importDestinationAddress: string | null;
    complaint: boolean;
    didntGoReason: number | null;
    resignReason: number | null;
    constructor(data: OrderBookingDto, passengers: OrderPassengerDto[]) {
        this.id = data.id;
        this.fiscalizedAt = data.fiscalizedAt ? new Date(data.fiscalizedAt) : null;
        this.planningType = data.planningType;
        this.dateHistory = data.dateHistory;
        this.filter = data.filter ? new FilterModel(data.filter) : null;
        this.computedItemRidePaymentStatus = data.computedItemRidePaymentStatus;
        this.itineraries = new Itineraries(data.itineraries);
        this.route = new BookingRouteModel(data.route);
        this.items = data.items.map((item) => new ItemOrderModel(item));
        this.didntGo = data.didntGo;
        this.externalId = data.externalId;
        this.uniqueNumber = data.uniqueNumber;
        this.type = data.type;
        this.passengerId = <string>data.passengerId;
        this.passenger = new OrderPassengerModel(passengers.find((passenger) => passenger.id === this.passengerId)!);
        this.importDepartureAddress = data.importDepartureAddress || null;
        this.importDestinationAddress = data.importDestinationAddress || null;
        this.complaint = data.complaint;
        this.notes = data.notes;
        this.driverNotes = data.driverNotes;
        this.internalNotes = data.internalNotes;
        this.didntGoReason = data.didntGoReason;
        this.resignReason = data.resignReason;
    }
}

export class OrderBookingParcelModel {
    id: string;
    fiscalizedAt: Date | null;
    planningType: number;
    dateHistory: string[];
    filter: FilterModel | null;
    computedItemRidePaymentStatus: string;
    itineraries: Itineraries;
    route: BookingRouteModel;
    items: ItemOrderModel[];
    didntGo: boolean;
    teamNumber: string | null;
    externalId: number | null;
    uniqueNumber: number | null;
    type: number;
    parcelId: string;
    parcel: OrderParcelModel;
    importDepartureAddress: string | null;
    importDestinationAddress: string | null;
    complaint: boolean;
    driverNotes: string | null;
    notes: string | null;
    internalNotes: string | null;
    didntGoReason: number | null;
    resignReason: number | null;
    constructor(data: OrderBookingDto, parcels: ParcelOrderDto[]) {
        this.id = data.id;
        this.fiscalizedAt = data.fiscalizedAt ? new Date(data.fiscalizedAt) : null;
        this.planningType = data.planningType;
        this.dateHistory = data.dateHistory;
        this.filter = data.filter ? new FilterModel(data.filter) : null;
        this.computedItemRidePaymentStatus = data.computedItemRidePaymentStatus;
        this.itineraries = new Itineraries(data.itineraries);
        this.route = new BookingRouteModel(data.route);
        this.items = data.items.map((item) => new ItemOrderModel(item));
        this.didntGo = data.didntGo;
        this.teamNumber = data.teamNumber;
        this.externalId = data.externalId;
        this.uniqueNumber = data.uniqueNumber;
        this.type = data.type;
        this.parcelId = <string>data.parcelId;
        this.parcel = new OrderParcelModel(parcels.find((parcel) => parcel.id === this.parcelId)!);
        this.importDepartureAddress = data.importDepartureAddress || null;
        this.importDestinationAddress = data.importDestinationAddress || null;
        this.complaint = data.complaint;
        this.driverNotes = data.driverNotes;
        this.internalNotes = data.internalNotes;
        this.notes = data.notes;
        this.didntGoReason = data.didntGoReason;
        this.resignReason = data.resignReason;
    }
}

export class OrderModel {
    id: string;
    number: string | null;
    invoice: boolean;
    date: Date;
    status: string;
    comments: string;
    route: OrderRouteModel | null;
    customer: OrderCustomerModel;
    bookings: (OrderBookingPersonModel | OrderBookingParcelModel)[];
    transactions: TransactionOrderModel[];
    payments: PaymentsOrderModel[];
    language: string | null;
    discountCode: string | null;
    partnerId: string | null;
    partner: PartnerModel | null;
    teamNumber: string | null;

    constructor(data: OrderModelDto) {
        this.id = data.id;
        this.number = data.number;
        this.invoice = data.invoice;
        this.date = new Date(data.date);
        this.status = data.status;
        this.comments = data.comments;
        this.route = data.route ? new OrderRouteModel(data.route) : null;
        this.customer = new OrderCustomerModel(data.customer);
        this.bookings = data.bookings.map((item: OrderBookingDto): OrderBookingParcelModel | OrderBookingPersonModel => {
            if ('parcelId' in item) {
                return new OrderBookingParcelModel(item, data.parcels);
            }

            return new OrderBookingPersonModel(item, data.passengers);
        });
        this.transactions = data.transactions.map((item) => new TransactionOrderModel(item));
        this.payments = data.payments.map((item) => new PaymentsOrderModel(item));
        this.language = data.language;
        this.discountCode = data.discountCode;
        this.partnerId = data.partner?.id || null;
        this.partner = data.partner ? new PartnerModel(data.partner) : null;
        this.teamNumber = data.teamNumber;
    }
}
