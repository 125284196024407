import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { OrderModel } from '../models/order.model';
import { OrderUpdateValue, OrderValue } from '../models/order.value';
import { CheckPrerequisitesModel } from '../models';

export const OrderActions = createActionGroup({
    source: 'Order',
    events: {
        'Get Order': props<{ id: string }>(),
        'Get Order Success': props<{ payload: OrderModel }>(),
        'Get Order Error': props<{ error: HttpErrorResponse }>(),
        'Add Order': props<{ value: OrderValue }>(),
        'Add Order Success': props<{ payload: OrderModel }>(),
        'Add Order Error': props<{ error: HttpErrorResponse }>(),
        'Update Order': props<{ id: string; value: OrderUpdateValue }>(),
        'Update Order Success': props<{ payload: OrderModel }>(),
        'Update Order Error': props<{ error: HttpErrorResponse }>(),
        'Check Order Prerequisities Create': props<{ value: OrderValue }>(),
        'Check Order Prerequisities Create Success': props<{ prerequisities: CheckPrerequisitesModel }>(),
        'Check Order Prerequisities Create Error': props<{ error: HttpErrorResponse }>(),
        'Check Order Prerequisities Update': props<{ value: OrderUpdateValue; orderId: string }>(),
        'Check Order Prerequisities Update Success': props<{ prerequisities: CheckPrerequisitesModel }>(),
        'Check Order Prerequisities Update Error': props<{ error: HttpErrorResponse }>(),
        Clear: emptyProps(),
    },
});
