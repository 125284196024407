import { ACCURACY_TYPES } from '../enums/accuracy.enum';
import { BookingRouteAddressDto, GeoPosition, OrderCustomerAddressDto } from './order.addresses.dto';

export class OrderRouteAddressModel {
    id: string | null;
    term: string;
    panelId: number | null;
    isPoint: boolean;
    hourFrom: string | null;
    hourTo: string | null;
    country: string | null;
    city: string | null;
    voivodeship: string | null;
    district: string | null;
    street: string | null;
    houseNumber: string | null;
    flatNumber: string | null;
    postalCode: string | null;
    locationId: string | null;
    geoPosition: GeoPosition;
    accuracy: ACCURACY_TYPES;
    constructor(data: BookingRouteAddressDto) {
        this.id = data.id;
        this.term = data.searchValue || '';
        this.panelId = data.panelId;
        this.isPoint = data.isPoint;
        this.hourFrom = data.hourFrom;
        this.hourTo = data.hourTo;
        this.country = data.country;
        this.city = data.city;
        this.voivodeship = data.voivodeship;
        this.district = data.district;
        this.street = data.street;
        this.houseNumber = data.houseNumber;
        this.flatNumber = data.flatNumber;
        this.postalCode = data.postalCode;
        this.locationId = data.locationId;
        this.geoPosition = data.geoPosition;
        this.accuracy = data.accuracy || ACCURACY_TYPES.HOUSE_NUMBER;
    }
}
export class OrderCustomerAddress {
    country: string | null;
    city: string | null;
    street: string | null;
    houseNumber: string | null;
    postalCode: string | null;
    // voivodeship: string | null;
    // flatNumber: string | null;
    geoPosition: GeoPosition | null;

    constructor(data: OrderCustomerAddressDto) {
        this.country = data.country;
        this.city = data.city;
        this.street = data.street;
        this.houseNumber = data.houseNumber;
        this.postalCode = data.postalCode;
        // this.voivodeship = data.voivodeship;
        // this.flatNumber = data.flatNumber;
        this.geoPosition = data.geoPosition;
    }
}
